import geneticsHero from '../assets/icons/icon-genetics.svg';
import dnaStrandIcon from '../assets/icons/icon-dna-strand.svg';
import pieChartIcon from '../assets/icons/icon-familial-pie-chart.svg';
import counselingIcon from '../assets/icons/icon-genetic-counseling.svg';

const geneticsCopy = {
  pageSeoTitle: 'Von Hippel-Lindau (VHL) Disease and Genetics',
  pageDescription: 'Von Hippel-Lindau (VHL) disease is a rare genetic condition that can run in families. See the difference between familial and first-in-family VHL disease, and read about genetic counseling.',
  pageKeywords: 'vhl genetics, von hippel-lindau disease genetics',

  hero: {
    title: 'VHL and genetics',
    imgSrc: geneticsHero,
    imgAlt: '',
    underline: 'lime',
  },

  aboutSection: {
    title: 'Von Hippel-Lindau (VHL) disease is a genetic condition that can run in families.',
    graphic: dnaStrandIcon,
    graphicAlt: '',
    copy: `<p>Cells in the body have two copies of each gene—one from each parent. </p> 
    <p>If one parent has VHL disease, there’s a <strong>50% chance</strong> that each of their children will have it too. People with a grandparent, aunt, uncle, or cousin with VHL disease may also be at risk.</p>
    <p>VHL disease affects people of all ethnic backgrounds and genders in all parts of the world.</p>`,   
    labelContent: 'If you or anyone in your family has VHL disease, talk to your doctor about genetic testing.',
  },

  familialSection: {
    title: 'Familial vs first-in-family',
    graphic: pieChartIcon,
    graphicAlt: 'About 80% of VHL Disease Is Familial (Runs in the Family) and About 20% Is De Novo (First in the Family).',
    copy: `<p>For most people with VHL disease (about 80%), their condition is called <strong>familial</strong>. Familial means that a family member has also been diagnosed with VHL disease. </p> 
    <p>About 20% of the time, someone is the first in their family to have VHL disease. Doctors may call this <strong>de novo</strong> or <strong>first-in-family</strong> VHL disease.  This means that this is the first time anyone in the family has had a mutation in the VHL gene. </p>
    <p>People can find it very hard to be the first person in the family with VHL disease. They may not know where to find doctors who manage VHL disease, and family members may not know the best way to give their support. <strong>If you or a loved one is a first-in-family VHL disease patient, ask your doctor about advocacy and support groups that can help.</strong></p>`,
    bottomSection: ``,
  },

  counselingSection: {
    title: 'What is genetic counseling?',
    graphic: counselingIcon,
    graphicAlt: '',
    copy: `<p>Doctors may refer patients to <strong>genetic counselors</strong>. Genetic counselors help people understand how genetic diseases may affect them and their families. They look at test results and family health histories to learn how likely it is that patients have genetic conditions.</p> 
    <p>If someone with VHL disease plans to have children, a genetic counselor can help them understand the risks of passing along the mutated gene. Family members of people with VHL disease may also meet with genetic counselors to understand their risks. <strong>Early diagnosis can help patients manage their VHL disease better</strong>.</p>`,
  },

  leftAndRightButton: {
    left: {
      label: 'What is VHL disease?',
      to: '/what-is-vhl/',
      shadow: 'pink'
    },
    right: {
      label: 'How is VHL diagnosed?',
      to: '/how-is-vhl-diagnosed/',
      shadow: 'green'
    }
  }

};

export default geneticsCopy;
