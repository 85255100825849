import styled from 'styled-components';
import { dotsBlue, white, greyBorder, limeGreen } from '../shared/colors';
import { respondTo } from '../shared/breakpoints';
import ShadowBox from '../../components/elements/ShadowBox';
import { WrapperEl } from '../shared/layout';
import CtaSection from '../../components/elements/CtaSection';
import Hero from '../../components/elements/Hero';

export const GeneticsHero = styled(Hero)`
    h1 {
        width: 100%;
        padding-bottom: 18px;
    }
`;

export const CtaAboutSection = styled(CtaSection)`
    ${respondTo.sm`
        padding: 68px 0 32px 0;
    `}
`;

export const GeneticsAboutLabelWrapper = styled(WrapperEl)`
    padding: 0 16px;
    margin-bottom: 65px;
    ${respondTo.sm`
        padding: 0 60px;
        margin-bottom: 90px;
    `}
`;

export const GeneticsLabel = styled(ShadowBox)`
    background-color: ${dotsBlue};
    color: ${white};
    font-size: 19px;
    line-height: 26px;
    padding: 24px 20px;
    font-weight: bold;
    text-align: center;
    box-shadow: 8px 11px 0px ${limeGreen};
    ${respondTo.sm`
        box-shadow: 13px 15px 0px ${limeGreen};
        padding: 25px 21px 25px 30px;
    `}
`;

export const FamilialSection = styled(CtaSection)`
    .pageSectionWrapper {
        position: relative;
        &:before, &:after {
            content: '';
            position: absolute;
            height: 1px;
            background-color: ${greyBorder};
            width: calc(100% - 30px);
            left: 15px;
            top: -35px;
            ${respondTo.sm`
                top: -55px;
                left: 0;
                width: 100%;
            `}
        }
        &:after {
            top: calc(100% + 24px);
            ${respondTo.sm`
                top: calc(100% + 40px);
            `}
        }
    }

    .ctaContentContainer + div {
        p {
            margin-top: 16px;
        }
    }
`;

export const CounselingSection = styled(CtaSection)`
    padding-bottom: 0;
    .ctaContentContainer {
        gap: 15px;
    }
`;

